<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title>Boram Operations</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <section class="padding-x-16">
        <h1>Login</h1>
      </section>

      <!-- Google Auth Temporarily Disabled -->
      <!-- <section class="ion-padding" v-if="false">
        <p>Please use your Boram email to sign in</p>
        <ion-button expand="block" @click="googleLogin">Login with Boram email</ion-button>

        <p class="ion-text-center">
          If you have problems, please contact tech@boramcare.com
        </p>

        <p class="ion-text-center margin-top-24">v {{ version }}</p>
        <p class="ion-text-center ion-text-capitalize">{{ environment }}</p>
      </section> -->

      <!-- Email Auth -->
      <section class="ion-padding" v-if="true">
        <v-form @submit="onSubmit">
          <ion-grid>
            <ion-row>
              <ion-col>
                <!-- Email -->
                <base-input label-text="Email *">
                  <v-field name="email" v-slot="{ field }" v-model="login.email" :rules="requiredEmail">
                    <ion-input name="email" v-bind="field" placeholder="janedoe@boramcare.com" type="email"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="email" class="error-message"></v-error-message>
              </ion-col>
            </ion-row>

            <ion-row v-if="requested">
              <ion-col>
                <!-- Code -->
                <base-input label-text="Code *">
                  <v-field name="code" v-slot="{ field }" v-model="login.code" :rules="required">
                    <ion-input name="code" v-bind="field" type="number"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="code" class="error-message"></v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block">
            {{ requested ? "Login" : "Request Verification Code" }}
          </ion-button>
        </v-form>

        <!-- Resend Verification Code only if requested -->
        <ion-button expand="block" fill="clear" class="margin-top-24" v-if="requested" @click="request">
          Resend Verification Code
        </ion-button>

        <!-- Version -->
        <div class="ion-text-center padding-y-8">
          <p class="small">v. {{ $version }}</p>
          <p class="small ion-text-capitalize">{{ environment }}</p>
        </div>
      </section>
    </ion-content>

    <ion-toast :is-open="showToast" :message="toast.message" :duration="2000" @didDismiss="resetToast"
      :color="toast.color">
    </ion-toast>

    <ion-loading :is-open="showLoading" message="Please wait..." @didDismiss="setShowLoading(false)">
    </ion-loading>
  </ion-page>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonContent,
  IonInput,
  IonLoading,
  IonToast
} from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";

// import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth"; // Temporarily Disabled

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonContent,
    IonInput,
    IonLoading,
    IonToast,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      login: {
        email: "",
        code: "",
      },
      requested: false,
      requiredEmail: yup.string().email().required(),
      required: yup.string().required(),
    };
  },

  computed: {
    ...mapGetters(["showLoading", "toast", "showToast"]),

    environment() {
      if (process.env.VUE_APP_SERVER === "https://api-staging.boram.live") return "staging";
      else if (process.env.VUE_APP_SERVER === "https://api.boram.live") return "production";
      else if (process.env.VUE_APP_SERVER === "http://127.0.0.1:3333") return "local";
      else return "enviornment unknown";
    },
  },

  ionViewWillEnter() {
    // Turn off Loading Indicator if it's stuck
    this.setShowLoading(false)
  },

  // mounted() {
  //   DISABLED TEMPORARILY 
  //   GoogleAuth.initialize({
  //     clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  //     grantOfflineAccess: true,
  //     scopes: ["profile", "email"],
  //   });
  // },

  methods: {
    ...mapActions(["resetToast", "loadToast", "setShowLoading", "authLogin"]),

    /**
     * Form Submit, either requests a code or verifies the entered code
     */
    async onSubmit() {
      // Request a Code
      if (!this.requested) this.request();

      // Verify a Code
      else if (this.requested) this.verify();
    },


    /**
     * Request a Code
     */
    async request() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/auth/request`,
        data: this.login,
      };

      // Turn on Loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Turn off Loading indicator
          this.setShowLoading(false);

          // Successfully requested code
          if (response.data.message) {
            this.requested = true;

            this.loadToast({ message: response.data.message, color: "secondary" });
          }

          // Error requesting code
          else if (response.data.error) {
            this.loadToast({ message: response.data.error, color: "danger" });
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off Loading indicator
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },


    /**
     * Verify the code
     */
    async verify() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/auth/verify`,
        data: this.login,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Turn off Loading indicator
          this.setShowLoading(false);

          // Successfully verified code, login user
          if (response.data.user) {
            this.authLogin(response.data);

            this.$router.replace("/home");
          }

          // Error requesting code
          else if (response.data.error) {
            this.loadToast({ message: response.data.error, color: "danger" });
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off Loading indicator
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },



    /**
     * Auth with Google - Currently not used
     */
    // async googleLogin() {
    //   try {
    //     const response = await GoogleAuth.signIn();

    //     // If successful, then try to get API auth details
    //     if (response.id) {
    //       const config = {
    //         method: "post",
    //         url: `${process.env.VUE_APP_API}/auth/google`,
    //         data: response,
    //       };

    //       // Show a network loading indicator
    //       this.setShowLoading(true);

    //       await this.axios(config)
    //         .then((response) => {
    //           // Turn off the network loading indicator and prompt user with a toast
    //           this.setShowLoading(false);

    //           // Successfully got authObject
    //           if (response.data.user) {
    //             this.authLogin(response.data);

    //             this.$router.replace("/home");
    //           }

    //           // Error in getting authObject
    //           else if (response.data.error) {
    //             this.loadToast({
    //               message: response.data.error,
    //               color: "danger",
    //             });
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);

    //           // Turn off the network loading indicator and prompt user with a toast
    //           this.setShowLoading(false);
    //           this.loadToast({
    //             message: "Something went wrong",
    //             color: "danger",
    //           });
    //         });
    //     }
    //   }
    //   catch (e) { }
    // },
  },
};
</script>

<style scoped>
ion-toolbar {
  --background: #fff;
}
</style>